import React, { Component } from "react"
import "../scss/policies.scss"
import { OutboundLink } from "gatsby-plugin-google-analytics"

import Layout from "../components/layout"
import SEO from "../components/seo"
import { setDefaultHeaderTheme, navbarUpdate } from "../utils/common-util"

class Policies extends Component {
  componentDidMount() {
    setDefaultHeaderTheme(true)
    navbarUpdate()
  }

  render() {
    const mailId = "legal@iwizardsolutions.com"

    return (
      <Layout>
        <SEO title="Privacy Policies" />
        <div className="policy-contents iww-full-height">
          <div id="stars1" className="stars"></div>
          <div id="stars2" className="stars"></div>
          <div id="stars3" className="stars"></div>
          <div className="pc-page-wrap iww-container-mr">
            <div className="pc-main-head">
              PRIVACY POLICY FOR INTEGRATION WIZARDS SOLUTIONS PVT LTD
            </div>
            <div className="pc-head">
              Integration Wizards’ Privacy Commitment
            </div>
            <div className="pc-para">
              Integration Wizards has worried about customer and user privacy
              from its inception. We only ask for the least amount of
              information necessary, gathering only what we believe is essential
              for doing business, or for the specific transaction at hand. Our
              biggest commitment is that we do not make a single dollar from
              advertising revenue—never have, never will—even from the free
              editions of our products. This means we avoid the fundamental
              conflict of interest between gathering customer information and
              fuelling advertising revenue, and the unavoidable compromises in
              customer privacy that it brings.
              <br />
              <br />
              The goal of this policy is to make explicit the information we
              gather on our customers and users, how we will use it, and how we
              will not. This policy is unfortunately longer than we would like,
              but we must unambiguously address all the relevant cases. We will
              try and keep the language simple and direct as much as possible.
              <br />
              <br />
              For the purpose of this Privacy Policy, and where the context so
              requires, “Integration Wizards ” , “Company”, “our”, “we” or “us”
              shall mean Integration Wizards Solutions Pvt Ltd, and wherever the
              context so requires, the “Customer”, “End User”, “User”, “you” or
              “your” will refer to any organisation, individual or body
              corporate who uses the Application and Services.
              <br />
              <br />
              Integration Wizards Solutions Pvt Ltd provides customised computer
              vision solutions built upon machine learning technologies as well
              as enterprise mobility solutions (From Hereinafter called
              “Application”), to provide insights and analytics such as but not
              limited to, the deviation in standard operating procedures, KPI’s
              specified by the customer, and make them trackable for all
              stakeholders within the organisation (From Hereinafter called
              “Services”).
              <br />
              <br />
              We, at Integration Wizards, appreciate the trust and information
              entrusted upon us and seek to protect your data to the best
              possible extent and use it in only in accordance with this privacy
              policy.
              <br />
              <br />
              This privacy policy has been published to help the customer and
              the end-user understand what information (of the Customer and End
              User) is collected by Integration Wizards, and the purpose, means,
              and modes of usage and how or to whom this information will be
              disclosed.
              <br />
              <br />
              Your use of the Services is at all times subject to the Customer
              Agreement. Any terms we use in this Privacy Policy without
              defining them have the definitions given to them in the Customer
              Agreement or the Terms of Use.
            </div>
            <div className="pc-head">Definitions</div>
            <div className="pc-para">
              For the purpose of this Privacy Policy, the key terms are defined
              as follows:
            </div>
            <ol>
              <li>
                “Billing Information” shall have the meaning ascribed to it in
                Clause 4.1 of this Privacy Policy;
              </li>
              <li>
                “Customer” shall mean the legal entity which executes a Customer
                Agreement with Integration Wizards in order to access and/or
                avail the Services;
              </li>
              <li>
                “Customer Account” shall mean a Customer’s account created by
                Integration Wizards for enabling access to the Services;
              </li>
              <li>
                “Customer Agreement” shall mean the agreement executed between
                Integration Wizards and the Customer;
              </li>
              <li>
                “Data” means the Personally Identifiable Information and/or
                Sensitive Personal Information that Integration Wizards obtains
                when it
                <ol>
                  <li>
                    collects such information from the Customer and/or End User
                    when they use the Application, or
                  </li>
                  <li>
                    collects such information automatically when the Customer
                    and/or End User use the Application. However, Data shall not
                    include Personally Identifiable Information or Sensitive
                    Personal Information.
                  </li>
                </ol>
              </li>
              <li>
                “End User” shall mean a user accessing the Application through
                services provided by the Customer or a user whose data is
                collected by Integration Wizards through its Services and shall
                include a user who accesses the Website;
              </li>
              <li>
                “Facial Data” shall have the meaning ascribed to it in Clause
                3.1 of this Privacy Policy;
              </li>
              <li>
                “Linked Sites” shall have the meaning ascribed to it in Clause
                13.1 of this Privacy Policy;
              </li>
              <li>
                “Metadata” shall refer to any set of data generated as a result
                of providing the Services, that gives information regarding
                other data (whether the same is processed or unprocessed);
              </li>
              <li>
                “Raw Data” shall refer to any data (excluding Metadata)
                collected and stored by Integration Wizards, but not otherwise
                processed, as a result of providing the Services;
              </li>
              <li>
                “Personally Identifiable Information” or “Sensitive Personal
                Information” means any information that relates to a natural
                person, which, either directly or indirectly, in combination
                with other information available or likely to be available with
                a body corporate, can identify such person;
              </li>
              <li>
                “Website” shall mean the website operated by Integration Wizards
                accessible at http://iwizardsolutions.com;
              </li>
            </ol>
            <div className="pc-head">Consent</div>
            <div className="pc-para">
              This Privacy Policy governs your use of the Application, the
              Services and the Website. By using or continuing to use the
              Website, Application and/or Services, you provide your consent to
              Integration Wizards to transfer, share and disclose your
              Personally Identifiable Information or Sensitive Personal
              Information in accordance with this Privacy Policy.
            </div>
            <div className="pc-head">Data that we collect</div>
            <div className="pc-para">
              We record information that you submit when you
            </div>
            <ol>
              <li>
                register for any event, including webinars or seminars organised
                by us,
              </li>
              <li>subscribe to our newsletter or any other mailing list,</li>
              <li>
                submit a form to download any product, white-paper, or other
                materials,
              </li>
              <li>participate in contests or respond to surveys, or</li>
              <li>
                submit a form to request customer support or to contact
                Integration Wizards for any other purpose. The Data we collect
                from you includes, but is not limited to, the following:
                <ol>
                  <li>
                    Name and contact data: This can include your first name,
                    last name, email address, postal address, phone number or
                    any other similar data;
                  </li>
                  <li>
                    Credentials: Passwords, password hints, and similar security
                    information used for authentication;
                  </li>
                  <li>
                    Subscription and license data: Data about any subscription,
                    plan or other features that you have selected related to the
                    Application;
                  </li>
                  <li>
                    Testimonials: When you authorise us to post testimonials
                    about our products and services on our website or
                    Integration Wizards’ social media pages, we may include your
                    name and other personal information in the testimonial. You
                    will be allowed to review and approve the testimonial before
                    we post it. If you wish to update or delete your
                    testimonial, you can contact us at
                    <OutboundLink
                      target="_blank"
                      rel="noopener noreferrer"
                      className="legal-email"
                      href={"mailto:" + mailId}
                    >
                      {" " + mailId}
                    </OutboundLink>
                    ;
                  </li>
                  <li>
                    We may collect images and videos and information relating to
                    images or videos, such as patterns associated with images or
                    videos for facial recognition of End Users (“Facial Data”).
                    However, no Facial Data is stored by us without the End
                    User’s consent.
                  </li>
                  <li>
                    We may collect Raw Data in the process of providing the
                    Services, which may contain Personally Identifiable
                    Information.
                  </li>
                  <li>
                    We may record, analyse and use your interactions with us,
                    including email, telephone, and chat conversations with our
                    sales and customer support professionals, for improving our
                    interactions with you and other customers.
                  </li>
                  <li>
                    Data automatically collected: During your usage of our
                    Application, Integration Wizards will be collecting the
                    following Data automatically:
                    <ol>
                      <li>
                        Data about your device, including information about your
                        device’s hardware and software, error reports, and
                        troubleshooting and help data;
                      </li>
                      <li>
                        Location data: We collect the details of your device’s
                        location using any Global Navigation Satellite System
                        (for example, GPS) or using any other technology that
                        will enable us to collect your location;
                      </li>
                    </ol>
                  </li>
                  <li>
                    Information that we collect from third parties:
                    <ol>
                      <li>
                        Referrals: If someone has referred any of our products
                        or services to you, that person may have provided us
                        with your name, email address and other personal
                        information. You may contact us at{" "}
                        <OutboundLink
                          target="_blank"
                          rel="noopener noreferrer"
                          className="legal-email"
                          href={"mailto:" + mailId}
                        >
                          {" " + mailId + " "}
                        </OutboundLink>{" "}
                        to request that we remove your information from our
                        database. If you provide information about another
                        person, or if another person gives us your information,
                        we will only use that information for the specific
                        reason for which it was provided to us.
                      </li>
                      <li>
                        Information from our reselling partners and service
                        providers: If you contact any of our reselling partners,
                        or otherwise express interest in any of our products or
                        services to them, the reselling partner may pass your
                        name, email address, company name and other information
                        to Integration Wizards.
                      </li>
                      <li>
                        If you register for or attend an event that is sponsored
                        by Integration Wizards, the event organizer may share
                        your information with us.
                      </li>
                      <li>
                        Information from social media sites and other publicly
                        available sources: When you interact or engage with us
                        on social media sites such as Facebook, Twitter,
                        LinkedIn, Instagram, and YouTube through posts,
                        comments, questions and other interactions, we may
                        collect such publicly available information, including
                        profile information, to allow us to connect with you,
                        improve our products, or better understand user
                        reactions and issues. Once collected, this information
                        may remain with us even if you delete it from the social
                        media sites. Integration Wizards may also add and update
                        information about you, from other publicly available
                        sources.
                      </li>
                    </ol>
                  </li>
                </ol>
              </li>
            </ol>
            <div className="pc-head">Your Billing Information</div>
            <div className="pc-para">
              To purchase our Services, you are required to make a payment for
              the same. While making payment, we redirect you to our third-party
              payment gateway and at such third party payment gateway your
              billing details such as billing name and address, and a credit
              card number, including card security number and credit card date
              of expiration (“Billing Information”), is collected by such third
              party payment gateway. We do not collect or store your Billing
              Information on the Website. You agree and acknowledge that your
              submission of Billing Information on the third-party payment
              gateway will be governed by the terms of such third-party payment
              gateway’s privacy policy and terms of use.
            </div>
            <div className="pc-head">Retention of Data</div>
            <div className="pc-para">
              Unless permitted by applicable law, Integration Wizards will
              retain all Personally Identifiable Information and Sensitive
              Personal Information only for as long as necessary for Integration
              Wizards to perform its obligations under the Customer Agreement,
              Terms of Use and under this Privacy Policy. Sometimes, we may
              retain your information for longer periods as permitted or
              required by law, such as to maintain suppression lists, prevent
              abuse, if required in connection with a legal claim or proceeding,
              to enforce our agreements, for tax, accounting, or to comply with
              other legal obligations. When we no longer have a legitimate need
              to process your information, we will delete or anonymize your
              information from our active databases. We will also securely store
              the information and isolate it from further processing on backup
              discs until deletion is possible.
            </div>
            <div className="pc-head">Sharing/transferring your Data</div>
            <div className="pc-para">
              We do not sell any personal information. We share your information
              only in the ways that are described in this Privacy Policy, and
              only with parties who adopt appropriate confidentiality and
              security measures.
            </div>
            <ol>
              <li>
                Integration Wizards will share your Data only in accordance with
                this Privacy Policy. We may also hire third parties to provide
                services on our behalf for which we may have to share your Data
                with such third parties. However, we will only share your Data
                to provide the Services. These third parties are contractually
                required to maintain the confidentiality of your Data and are
                contractually prohibited from using your Data for any other
                purpose.
              </li>
              <li>
                We may share anonymized or aggregated data we collect from the
                use of the Services, such as de-identified demographic
                information, de-identified location information, information
                about the computer or device from which you access the Services,
                market trends and other analysis that we create based on the
                information we receive from you and other users.
              </li>
              <li>
                Integration Wizards may disclose the Data to its employees,
                service providers and third-party consultants in order to
                improve its Services or the Application. We may share your Data
                in various situations including but not limited to the following
                conditions:
                <ol>
                  <li>
                    After we have obtained your consent for the same. Your use
                    of the Application and the Services will be deemed consent
                    to the terms of this Privacy Policy including but not
                    limited to sharing your Data; or
                  </li>
                  <li>
                    When we believe in good faith that disclosure is necessary
                    to protect our rights or property; or
                  </li>
                  <li>To protect your rights and safety; or</li>
                  <li>
                    To investigate fraud or respond to a government, judicial or
                    other legal requests; or
                  </li>
                  <li>To comply with any applicable law.</li>
                </ol>
              </li>
            </ol>
            <div className="pc-head">Data Protection Measures</div>
            <div className="pc-para">
              We maintain organizational, physical and technical security
              arrangements for all Data we hold, including maintaining security
              of your information.
              <br />
              <br />
              We review the information collection, storage and processing
              practices, including physical security measures, to continually
              guard against unauthorized access to our systems.
              <br />
              <br />
              We restrict access to the Data, to only employees and agents who
              need to know such information in order to process it in accordance
              with this Privacy Policy, and who are subject to strict
              contractual confidentiality obligations.
              <br />
              <br />
              We will adopt all requirements necessary for the protection of
              your Data as required by applicable law.
            </div>
            <div className="pc-head">Your Choice In Information Use</div>
            <ol>
              <li>
                <i>Opt-out of non-essential electronic communications:</i> You
                may opt-out of receiving non-essential messages by sending an
                email to{" "}
                <OutboundLink
                  target="_blank"
                  rel="noopener noreferrer"
                  className="legal-email"
                  href={"mailto:" + mailId}
                >
                  {" " + mailId}
                </OutboundLink>
                . However, you will continue to receive notices and essential
                transactional emails.
              </li>
              <li>
                <i>Disable cookies:</i> You can disable browser cookies before
                visiting our website. However, if you do so, you may not be able
                to use certain features of the website properly.
              </li>
              <li>
                If you enable cookies, the information we collect with cookies
                is not sold, rented, or shared with any third parties, other
                than for
                <ol>
                  <li>internal development and maintenance of the website,</li>
                  <li>enhancing your experience,</li>
                  <li>
                    providing custom, personalized content and information,
                    including relevant advertising in the course of providing
                    the Services.
                  </li>
                </ol>
              </li>
              <li>
                <i>Optional information:</i> You can choose not to provide
                optional profile information such as your photo. You can also
                delete or change your optional profile information. You can
                always choose not to fill in non-mandatory fields when you
                submit any form linked to our website.
              </li>
              <li>
                <i>Information from mobile devices:</i> When you elect to allow
                it, some of our mobile applications have access to the camera,
                call history, contact information, photo library, and other
                information stored on your mobile device.
                <br />
                <br />
                Our applications require such access to provide their services.
                Similarly, when you elect to provide access, location-based
                information is also collected and will be used only for mapping
                user locations.
                <br />
                <br />
                You may disable the mobile applications' access to this
                information at any time by editing the settings on your mobile
                device. The data stored on your mobile device and their location
                information to which the mobile applications have access will be
                used in the context of the mobile application, and transferred
                to and associated with your account in the corresponding
                services (in which case the data will be stored on our servers)
                or products (in which case the data will remain with you unless
                you share it with us).
              </li>
              <li>
                <i>Push notifications:</i> If you have enabled notification on
                our desktop and mobile applications, we will push notifications
                through a push notification provider such as Apple Push
                Notification Service, Google Cloud Messaging or Windows Push
                Notification Services. You can manage your push notification
                preferences or deactivate these notifications by turning off
                notifications in the application or device settings.
              </li>
              <li>
                <i>Blogs:</i> We have publicly accessible blogs and case studies
                on our website. We urge you to be cautious in disclosing
                personal information on our blogs. Integration Wizards is not
                responsible for the personal information you elect to disclose
                publicly. Your posts and certain profile information may remain
                even after you terminate your account with Integration Wizards.
              </li>
              <li>
                <i>Social media widgets:</i> Our blog website includes social
                media widgets such as Facebook "like" buttons and Twitter
                "tweet" buttons that let you share articles and other
                information. These widgets may collect information such as your
                IP address and the pages you navigate in the website and may set
                a cookie to enable the widgets to function properly. Your
                interactions with these widgets are governed by the privacy
                policies of the companies providing them.
              </li>
            </ol>
            <div className="pc-head">
              How Will Integration Wizards Use Your Data
            </div>
            <div className="pc-para">We use this information to:</div>
            <ol>
              <li>
                provide you with the Services and to maintain, manage and
                improve the Application and Services;
              </li>
              <li>send you notifications and communicate with you;</li>
              <li>
                provide you with customer support including technical support
                and troubleshooting;
              </li>
              <li>
                protect you and conduct security investigations and fraud and
                abuse analysis;
              </li>
              <li>
                comply with our legal obligations, for example when assisting
                governments and law enforcement agencies or regulators (as may
                be required by applicable law);
              </li>
              <li>
                improve the safety and reliability of the Application. This
                includes detecting, preventing, and responding to fraud, abuse,
                security risks, and technical issues that could harm the
                Application, you, our users, or the general public;
              </li>
              <li>
                analyse data, tracking trends, building algorithms and source
                code, creating databases for rating systems, recommendations
                engines, etc.;
              </li>
              <li>
                to investigate, prevent or take action regarding illegal
                activities, suspected fraud, situations involving potential
                threats to the safety of any person, violations of the Terms of
                Use and/or the Customer Agreement, or as otherwise required by
                applicable law;
              </li>
              <li>
                to conduct internal and external audits and quality assessment
                procedures; and
              </li>
              <li>to comply with applicable law.</li>
            </ol>
            <div className="pc-head">Business Transfers</div>
            <div className="pc-para">
              We do not intend to sell our business. However, in the unlikely
              event that we sell or get acquired or merged, we will ensure that
              the acquiring entity is legally bound to honour our commitments to
              you. We will notify you via email or through a prominent notice on
              our website of any change in ownership or in the uses of your
              personal information and service data. We will also notify you
              about any choices you may have regarding your personal information
              and service data.
            </div>
            <div className="pc-head">Compliance with this Privacy Policy</div>
            <div className="pc-para">
              We make every effort, including periodic reviews, to ensure that
              the personal information you provide is used in conformity with
              this Privacy Policy. If you have any concerns about our adherence
              to this Privacy Policy or the manner in which your personal
              information is used, kindly write to us at{" "}
              <OutboundLink
                target="_blank"
                rel="noopener noreferrer"
                className="legal-email"
                href={"mailto:" + mailId}
              >
                {" " + mailId + " "}
              </OutboundLink>{" "}
              and we'll contact you, and if required, coordinate with the
              appropriate regulatory authorities to effectively address your
              concerns.
            </div>
            <div className="pc-head">Notification of changes</div>
            <ol>
              <li>
                We reserve the right to make changes to this Privacy Policy at
                any time. Any such modification will become effective
                immediately upon Integration Wizards posting it on the Website
                and your continued use of the Application and/or the Website
                constitutes your agreement to such modifications.
              </li>
              <li>
                If we make significant changes to the Privacy Policy that affect
                your rights, you will be provided with at least 30 days advance
                notice of the changes by email to your primary email address.
              </li>
              <li>
                You agree to periodically review the current version of the
                Privacy Policy posted by us. If we make any material changes to
                this Privacy Policy, we will notify you of those changes by
                posting them on the Application or by sending you an email or
                other notification, and we’ll update the “Last Updated Date” to
                indicate when those changes will become effective.
              </li>
              <li>
                You will not receive email notification of minor changes to the
                Privacy Policy.
              </li>
            </ol>
            <div className="pc-head">Governing Law and Dispute Resolution</div>
            <div className="pc-para">
              INTEGRATION WIZARDS SOLUTIONS, INDIA: This Agreement shall be
              governed by the laws of India. Any dispute arising out of or in
              connection with this Privacy Policy, including any questions
              regarding its existence, validity and termination, shall be
              referred to and finally resolved by a sole arbitrator appointed by
              the parties to the dispute jointly. Each party shall bear their
              own costs and expenses incurred in connection with the arbitration
              proceedings unless otherwise awarded by the arbitrator in the
              arbitral award.
              <br />
              <br />
              INTEGRATION WIZARDS SOLUTIONS, EUROPE: If you are an individual
              from the European Economic Area (EEA), our legal basis for
              information collection and use depends on the personal information
              concerned and the context in which we collect it. Most of our
              information collection and processing activities are typically
              based on
            </div>
            <ol>
              <li>contractual necessity</li>
              <li>
                one or more legitimate interests of Integration Wizards or a
                third party that are not overridden by your data protection
                interests, or
              </li>
              <li>your consent.</li>
              <li>
                Sometimes, we may be legally required to collect your
                information, or may need your personal information to protect
                your vital interests or those of another person.
              </li>
            </ol>
            <div className="pc-head">Withdrawal of consent</div>
            <div className="pc-para">
              Where we rely on your consent as the legal basis, you have the
              right to withdraw your consent at any time, but this will not
              affect any processing that has already taken place.
            </div>
            <div className="pc-head">Legitimate interests notice</div>
            <div className="pc-para">
              Where we rely on legitimate interests as the legal basis and those
              legitimate interests are not specified above, we will clearly
              explain to you what those legitimate interests are at the time
              that we collect your information.
            </div>
            <div className="pc-head">
              Compliance with Data Security Rights In Europe
            </div>
            <div className="pc-para">
              If you are in the European Economic Area (EEA), you have the
              following rights with respect to information that Integration
              Wizards holds about you. Integration Wizards undertakes to provide
              you the same rights no matter where you choose to live.
            </div>
            <ol>
              <li>
                <i>Right to access:</i> You have the right to access (and obtain
                a copy of, if required) the categories of personal information
                that we hold about you, including the information's source,
                purpose and period of processing, and the persons to whom the
                information is shared
              </li>
              <li>
                <i>Right to rectification:</i> You have the right to update the
                information we hold about you or to rectify any inaccuracies.
                Based on the purpose for which we use your information, you can
                instruct us to add supplemental information about you in our
                database.
              </li>
              <li>
                <i>Right to erasure:</i> You have the right to request that we
                delete your personal information in certain circumstances, such
                as when it is no longer necessary for the purpose for which it
                was originally collected.
              </li>
              <li>
                <i>Right to restriction of processing:</i> You may also have the
                right to request to restrict the use of your information in
                certain circumstances, such as when you have objected to our use
                of your data but we need to verify whether we have overriding
                legitimate grounds to use it.
              </li>
              <li>
                <i>Right to data portability:</i> You have the right to transfer
                your information to a third party in a structured, commonly used
                and machine-readable format, in circumstances where the
                information is processed with your consent or by automated
                means.
              </li>
              <li>
                <i>Right to object:</i> You have the right to object to the use
                of your information in certain circumstances, such as the use of
                your personal information for direct marketing.
              </li>
              <li>
                <i>Right to complain:</i> You have the right to complain to the
                appropriate supervisory authority if you have any grievance
                against the way we collect, use or share your information. This
                right may not be available to you if there is no supervisory
                authority dealing with data protection in your country.
              </li>
            </ol>
            <div className="pc-head">Data subject requests</div>
            <div className="pc-para">
              If you are from the European Economic Area and you believe that we
              store, use or process your information on behalf of one of our
              customers, please contact the customer if you would like to
              access, rectify, erase, restrict or object to processing, or
              export your personal data. We will extend our support to our
              customer in responding to your request within a reasonable
              timeframe.
            </div>
            <div className="pc-head">
              Disclosures in compliance with legal obligations
            </div>
            <div className="pc-para">
              We may be required by law to preserve or disclose your personal
              information and service data to comply with any applicable law,
              regulation, legal process or governmental request, including to
              meet national security requirements.
            </div>
            <div className="pc-head">Enforcement of our rights</div>
            <div className="pc-para">
              We may disclose personal information and service data to a third
              party if we believe that such disclosure is necessary for
              preventing fraud, investigating any suspected illegal activity,
              enforcing our agreements or policies, or protecting the safety of
              our users.
            </div>
            <div className="pc-head">Children’s personal information</div>
            <div className="pc-para">
              Our products and services are not directed to individuals under
              16. Integration Wizards does not knowingly collect personal
              information from children who are under 16 years of age. If we
              become aware that a child under 16 has provided us with personal
              information, we will take steps to delete such information. If you
              believe that a child under 16 years has provided personal
              information to us, please write to{" "}
              <OutboundLink
                target="_blank"
                rel="noopener noreferrer"
                className="legal-email"
                href={"mailto:" + mailId}
              >
                {" " + mailId + " "}
              </OutboundLink>{" "}
              with the details, and we will take the necessary steps to delete
              the information we hold about that child
            </div>
            <div className="pc-head">Data Security Declaration</div>
            <div className="pc-para">
              At Integration Wizards, we take data security very seriously and
              comply with industry standards. We have taken steps to implement
              appropriate administrative, technical & physical safeguards to
              prevent unauthorized access, use, modification, disclosure or
              destruction of the information you entrust to us.
              <br />
              <br />
              If you have any questions or concerns about our privacy practices,
              you can reach out to us by sending an email to{" "}
              <OutboundLink
                target="_blank"
                rel="noopener noreferrer"
                className="legal-email"
                href={"mailto:" + mailId}
              >
                {" " + mailId}
              </OutboundLink>
              .
            </div>
          </div>
        </div>
      </Layout>
    )
  }
}

export default Policies
